import '../css/style.scss';


/* WELCOME MESSAGE */

const welcomeMessage = "Bienvenue sur origenial.fr !\n"
      + "Que venez-vous faire dans les logs ?\n"
      + "Que ce soit pour un bug, par curiosité, ou pour obtenir réponse"
      + " à vos questions, n'hésitez pas à nous contacter par mail.";

/* INIT SCRIPT */

window.onload = () => {
  console.log(welcomeMessage);
};


/**
 * Details/summary HTML element
 * Only open one element at a time
 */
if (document.querySelector('details')) {
  // Fetch all the details elements
  const details = document.querySelectorAll('details');

  // Add onclick listeners
  details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", () => {
      // Close all details that are not targetDetail
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.removeAttribute("open");
        }
      });
    });
  });
}
